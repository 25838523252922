import { Gallery } from "./gallery"
const Slideshow = Gallery

export class Testimonial {
  constructor(element) {
    this.element = element
    this.slideshowEl = this.element.querySelector(".testimonial__wrapper")
    this.slides = this.element.querySelectorAll(".testimonial__slide")
    const randomSlide = Math.floor(Math.random() * this.slides.length)
    this.slideshow = new Slideshow(this.slideshowEl, {
      autoPlay: false,
      createThumbs: false,
      visibleSlides: 1,
      endless: true,
      reveal: randomSlide,
    })
  }

  refreshVisibleSlidesCount = (size) => {
    if (size == "small") {
      this.slideshow.options.endless = false
      this.slideshow.options.visibleSlides = 1
    } else if (size == "medium") {
      this.slideshow.options.endless = false
      this.slideshow.options.visibleSlides = 1
    } else {
      this.slideshow.options.visibleSlides = 1.49
    }
    this.slideshow._setWidthsAndPositions()
    this.slideshow.reveal(this.slideshow._current)
  }
}
