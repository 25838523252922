import "./google-fonts-1692602010323.css"
import "./main.scss"

import { BreakpointWatcher } from "./js/BreakpointWatcher"
import { ToggleElements } from "./js/toggleElements"
//import scrollmenu from "js/scrollmenu"
import "blissfuljs/bliss.shy.js"
import "blissfuljs/bliss._.js"
// import {Moodboard} from './js/Moodboard'
import { ActivitiesTeaser } from "./js/ActivitiesTeaser"
import { Testimonial } from "./js/Testimonial"
// import {NewsSlider} from './js/NewsSlider'
import TopicFilter from "./js/TopicFilter.js"
import "./js/feed_pagination"
// import './js/animation'
import "./js/event-filter"

const { breakpointsJson } = require("./breakpoints.json")

const $ = window.Bliss,
  $$ = $.$

$.ready().then(() => {
  const events = $$(".event_detail")
  if ($(".event_detail")) {
    for (let i = 0; i < events.length; i++) {
      const figure = events[i].children[1]
      let eveDate = figure.children[0].dateTime
      if (eveDate.includes("Mai")) {
        eveDate = eveDate.replace("Mai", "May")
      } else if (eveDate.includes("März")) {
        eveDate = eveDate.replace("März", "March")
      } else if (eveDate.includes("Oktober")) {
        eveDate = eveDate.replace("Oktober", "October")
      }
    }
  }
  new ToggleElements()

  $$(".richtext--expandable")._.addEventListener(
    "click",
    function (e) {
      const visibleClass = "accordion-is-visible"
      const accordion = e.currentTarget
      accordion.classList.toggle(visibleClass)

      if (accordion.classList.contains(visibleClass)) {
        this.textContent = this.dataset.lessText
      } else {
        this.textContent = this.dataset.moreText
      }
    },
    true,
  )
  // push.style.height = footerHeight + 'px'

  // mainContent.style.marginBottom = '-' + footerHeight + 'px'

  const buttonToggle = $(".button_container")
  const overlayToggle = $("#overlay")
  const navigationData = $(".navigation--data-menu")

  buttonToggle.onclick = function () {
    this.classList.toggle("active-burger")
    overlayToggle.classList.toggle("open")
    navigationData.classList.toggle("w--nav-menu-open")
    document.body.classList.toggle("nav-is-open")
  }

  const navArrow = $$(".navigation__arrows")
  navArrow.forEach((element) => {
    element.addEventListener("click", () => {
      const nextSubmenu = element.parentNode.parentNode.querySelector(
        ".navigation__main__menu-list",
      )
      nextSubmenu.classList.toggle("is--open")
      element.classList.toggle("rotate")
    })
  })
  const navPoints = $$(".navigation__main__menu")
  navPoints.forEach((nav) => {
    if (!nav.children[1]) {
      const navPoint = nav.children[0]
      const toggleElem = navPoint.children[0]
      toggleElem.classList.add("hide")
    }
  })
  const topicFilter = $("[data-topic-filter]")

  if (topicFilter !== null) {
    new TopicFilter(topicFilter)
  }

  // Group Slider
  const bpWatcher = new BreakpointWatcher(breakpointsJson)

  const activitiesTeaserEl = $$(".group-teaser")
  if (activitiesTeaserEl) {
    activitiesTeaserEl.forEach((element) => {
      const activitiesTeaser = new ActivitiesTeaser(element)
      bpWatcher.emitter.addListener(
        "breakpointChange",
        activitiesTeaser.refreshVisibleSlidesCount,
      )
      // must be after listener was added
      bpWatcher.checkQueries()
    })
  }

  // // News Slider
  // const newsSliderEl = $$('.news-slider')
  // if (newsSliderEl) {
  //   newsSliderEl.forEach(element => {
  //     const newsSlider = new NewsSlider(element)
  //     bpWatcher.emitter.addListener(
  //       'breakpointChange',
  //       newsSlider.refreshVisibleSlidesCount
  //     )
  //     // must be after listener was added
  //     bpWatcher.checkQueries()
  //   })
  // }

  // Testimonial Slider
  const testimonialSliderEl = $$(".testimonial")
  if (testimonialSliderEl) {
    testimonialSliderEl.forEach((element) => {
      const testimonialSlider = new Testimonial(element)
      bpWatcher.emitter.addListener(
        "breakpointChange",
        testimonialSlider.refreshVisibleSlidesCount,
      )
      // must be after listener was added
      bpWatcher.checkQueries()
    })
  }

  // // Moodboard Slider
  // const moodboardTeaserEl = $$('.w-slider')
  // if (moodboardTeaserEl) {
  //   moodboardTeaserEl.forEach(element => {
  //     const moodboardTeaser = new Moodboard(element)
  //     bpWatcher.emitter.addListener(
  //       'breakpointChange',
  //       moodboardTeaser.refreshVisibleSlidesCount
  //     )
  //     // must be after listener was added
  //     bpWatcher.checkQueries()
  //   })
  // }
})

/* window.addEventListener("load", () => {
  const targetList = $$("[data-anchor]")
  if (targetList.length >= 1) {
    new scrollmenu(["[data-link], [data-nav-link]"])
  }
}) */
