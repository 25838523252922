/* global monthTextArray, $, $$  */

$.ready().then(() => {
  // Event List Filter

  const d = new Date()
  const m = d.getMonth() + 1
  const y = d.getFullYear()

  const eventForm = $("#eventForm")
  const groupButtons = $$(".groupBtn")
  const calendarButton = $$(".calendarButton")
  const inputUpcoming = $("input[value='upcoming']")
  const inputPast = $("input[value='past']")
  const inputRange = $("input[value='range']")
  const buttonRange = $("button[value='range']")
  const resetCross = $$(".event-cross")
  const resetButton = $(".archive__button-clear")
  let searchIsActive = false
  const groupSelector = $("#id_group")
  const partnerEvent = $("button[name='partner-event']")
  const sfrEvent = $("button[name='groupsfr']")
  const sfrEvents = $("input[name='groupsfr']")

  if (eventForm) {
    partnerEvent.addEventListener("click", () => {
      sfrEvents.setAttribute("name", "")
    })

    sfrEvent.addEventListener("click", () => {
      sfrEvents.setAttribute("value", "all")
    })

    // Reset all inputs
    resetButton.addEventListener("click", () => {
      groupSelector.options.selectedIndex = 0
      inputUpcoming.checked = true

      sfrEvents.value = ""

      $("#range_year").name = ""
      $("#range_month").name = ""
      eventForm.submit()
    })

    // Reset group buttons by clicking on the cross
    resetCross.forEach((element) => {
      element.onclick = () => {
        groupSelector.options.selectedIndex = 0
        inputUpcoming.checked = true

        $("#range_year").name = ""
        $("#range_month").name = ""
        sfrEvents.setAttribute("name", "")
        eventForm.submit()
      }
    })

    /*    let partnerFilter = $("button[name='partner-event']");
    let innogroupInput = $("input[name='groupsfr']")

    partnerFilter.addEventListener('click', function() {
      innogroupInput.setAttribute('disabled', "true")
    })*/

    // iterate through group buttons
    groupButtons.forEach((clickEvent) => {
      clickEvent.addEventListener("click", function () {
        const groupValue = this.value
        const groupOptions = groupSelector.options
        for (let option, j = 0; (option = groupOptions[j]); j++) {
          if (option.value == groupValue) {
            groupSelector.selectedIndex = j
            groupSelector.dispatchEvent(new Event("change"))
            break
          }
        }
      })
    })

    // prevent form sending empty queries
    groupSelector.addEventListener("change", () => {
      if (inputUpcoming.checked === true || inputPast.checked === true) {
        $("#range_year").name = ""
        $("#range_month").name = ""
      }
      eventForm.submit()
    })

    // iterate through calendar buttons
    calendarButton.forEach((element) => {
      const attribute = element.getAttribute("value")
      element.onclick = () => {
        if (attribute == "upcoming") {
          inputUpcoming.checked = true
          $("#range_year").name = ""
          $("#range_month").name = ""
          searchIsActive = false
          eventForm.submit()
        } else if (attribute == "past") {
          inputPast.checked = true
          $("#range_year").name = ""
          $("#range_month").name = ""
          searchIsActive = false
          eventForm.submit()
        } else if (attribute == "range") {
          inputRange.checked = true
          $("#range_month").value = m
          $("#range_year").value = y
          eventForm.submit()
        }
      }
    })

    // prevent error when arrows are invisible
    if (buttonRange.classList.contains("archive__button--active")) {
      searchIsActive = true
    }

    let month = $("#range_month").value - 1
    let year = $("#range_year").value

    // stupid month array starts usually from 0
    const monthIntArray = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ]

    if (searchIsActive == true) {
      $(".archive__button-left").addEventListener("click", () => {
        month--

        if (month < 0) {
          year--
          month = 11
        }

        //left button click
        $("#range_label").innerText = `${monthTextArray[month]} ${year}`
        $("#range_month").value = monthIntArray[month]
        $("#range_year").value = year
        eventForm.submit()
        // $('#id_date_2').checked = true
      })

      //right button click
      $(".archive__button-right").addEventListener("click", () => {
        month++

        if (month > 11) {
          year++
          month = 0
        }
        $("#range_label").innerText = `${monthTextArray[month]} ${year}`
        $("#range_month").value = monthIntArray[month]
        $("#range_year").value = year
        eventForm.submit()
        //$('#id_date_2').checked = true
      })

      //get text month name from month number and write to span
      $("#range_label").innerText = `${monthTextArray[month]} ${year}`
    }
  }
})
