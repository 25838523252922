class TopicFilter {
  constructor(el) {
    this.el = el
    this.topics = this.el.querySelector("#topic")
    this.focus_area = this.el.querySelector("#focus_area")

    if (this.topics.value !== "") {
      this.el.classList.add("topics-filtered")
    }

    this.addListener()
  }

  addListener() {
    this.topics.addEventListener("change", () => {
      // reset the focus area
      this.focus_area.parentElement.removeChild(this.focus_area)

      if (this.topics.value === "") {
        // reset the topic
        this.topics.parentElement.removeChild(this.topics)
      }

      this.el.submit()
    })

    this.focus_area.addEventListener("change", () => {
      this.el.submit()
    })
  }
}

export default TopicFilter
