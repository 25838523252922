import { Gallery } from "bliss-gallery"
const Slideshow = Gallery

export class ActivitiesTeaser {
  constructor(element) {
    this.element = element
    this.slideshowElements = this.element.querySelectorAll(
      ".group-teaser__wrapper",
    )
    this.slideshowElements.forEach((el) => {
      this.slideshow = new Slideshow(el, {
        autoPlay: false,
        createThumbs: false,
        visibleSlides: 1,
      })
    })
  }

  refreshVisibleSlidesCount = (size) => {
    if (size == "small") {
      this.slideshow.options.visibleSlides = 1
    } else if (size == "medium") {
      this.slideshow.options.visibleSlides = 2
    } else {
      this.slideshow.options.visibleSlides = 4
    }
    this.slideshow._setWidthsAndPositions()
    this.slideshow.reveal(this.slideshow._current)
  }
}
