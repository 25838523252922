/* global documentMessage, $, $$  */

window.onload = () => {
  const feedContainer = document.getElementById("feed")
  if (!feedContainer) return

  const messageContainer = $("#messageContainer")
  const endpointUrl = feedContainer.getAttribute("data-feed-endpoint-url")

  const request = new XMLHttpRequest()

  request.addEventListener("load", () => {
    const data = JSON.parse(request.response)
    if (request.status == 401) {
      const template = document.createElement("template")

      template.innerHTML = ""
      messageContainer.appendChild(template.content)
    } else if (request.status == 403) {
      const template = document.createElement("template")
      const msg = feedContainer.getAttribute("data-error-message-403")
      template.innerHTML = `<p>${msg}</p>`
      feedContainer.appendChild(template.content)
    } else if (request.status == 200) {
      if (data["items"].length == 0) {
        const template = document.createElement("template")
        template.innerHTML = documentMessage
        messageContainer.appendChild(template.content)
      } else {
        let html = ""
        for (let i = 0; i < data["items"].length; i++) {
          const item = data["items"][i]["content"]
          html += item
          feedContainer.innerHTML = html
        }
      }
    }
    let getAllPages = data["pagination"]["page_count"]
    if (getAllPages > 1) {
      let currentPage = data["pagination"]["current_page"]
      const nextPage = data["pagination"]["next_page"]
      const previousPage = data["pagination"]["previous_page"]
      const pageList = $("#pageList")

      // Create Links equal the number of Pages
      getAllPages++
      let list = ""
      for (let i = 1; i < getAllPages; i++) {
        document.createTextNode(i)
        list += `<li class="document__pagination-item-${i}" style="list-style: none;margin: 0;padding: 0;"><a class="document__pagination-item"> ${i} </a></li>`
        pageList.innerHTML = list
      }

      // Find Active Page Number
      for (let i = 1; i < getAllPages; i++) {
        if (i === currentPage) {
          $(`.document__pagination-item-${i}`).classList.add(
            "document__list-active",
          )
        }
      }

      // Pagination by Number
      const paginationList = $$("#pageList a")
      paginationList.forEach((clickEvent) => {
        clickEvent.addEventListener("click", () => {
          request.open(
            "GET",
            `${endpointUrl}?page=${clickEvent.innerHTML}`,
            true,
          )
          request.send()
        })
      })

      // Hide first arrow
      if (currentPage == 1) {
        $("#feedPrev").classList.add("hide")
      } else {
        $("#feedPrev").classList.remove("hide")
      }
      // Hide last arrow
      if (currentPage == getAllPages - 1) {
        $("#feedNext").classList.add("hide")
      } else {
        $("#feedNext").classList.remove("hide")
      }

      // Next Page
      $("#feedNext").addEventListener("click", () => {
        if (nextPage > 1) {
          currentPage++
          request.open("GET", `${endpointUrl}?page=${currentPage}`, true)
          request.send()
        } else {
          request.abort()
        }
      })

      // Previous Page
      $("#feedPrev").addEventListener("click", () => {
        if (previousPage >= 1 && previousPage !== null) {
          currentPage--
          request.open("GET", `${endpointUrl}?page=${currentPage}`, true)
          request.send()
        } else {
          request.abort()
        }
      })
    } else {
      $(".document__pagination").classList.add("hide")
    }
  })

  request.open("GET", endpointUrl, true)
  request.send()
}
