import { EventEmitter } from "events"

export class BreakpointWatcher {
  constructor(breakpoints) {
    this._eventEmitter = new EventEmitter()
    this.breakpoints = breakpoints

    this.setupQueries()
  }

  setupQueries() {
    this.queries = []
    for (let index = 0; index < this.breakpoints.ints.length; index++) {
      let query
      if (index == 0) {
        query = window.matchMedia(
          this.maxWidth(this.breakpoints.ints[index + 1]),
        )
      } else if (index < this.breakpoints.ints.length - 1) {
        query = window.matchMedia(
          `${this.minWidth(this.breakpoints.ints[index])} and ${this.maxWidth(
            this.breakpoints.ints[index + 1],
          )}`,
        )
      } else {
        query = window.matchMedia(this.minWidth(this.breakpoints.ints[index]))
      }
      query.addListener((e) => {
        e.matches &&
          this._eventEmitter.emit(
            "breakpointChange",
            this.breakpoints.classes.split(" ")[index],
          )
      })
      this.queries.push(query)
    }
  }

  checkQueries() {
    this.queries.forEach((query, index) => {
      if (query.matches) {
        this._eventEmitter.emit(
          "breakpointChange",
          this.breakpoints.classes.split(" ")[index],
        )
      }
    })
  }

  maxWidth = (size) => `(max-width: ${size - 1}px)`
  minWidth = (size) => `(min-width: ${size}px)`

  get emitter() {
    return this._eventEmitter
  }
}
